import { useRouter } from "next/router";
import { useEffect } from "react";
// import Error from "next/error";

export default function NotFound(): JSX.Element {
  const router = useRouter();

  useEffect(() => {
    router.replace("/");
  });

  return null;
  // Opinionated: do not record an exception in Sentry for 404s
  // return <Error statusCode={404} />;
}
